import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full'},
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'ai_setup',
        loadChildren: () =>
          import('./ai-setup/ai-setup.module').then((m) => m.AiSetupModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'on_agent',
        loadChildren: () =>
          import('./onboard-agents/onboard-agents.module').then((m) => m.OnboardAgentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'common-setup',
        loadChildren: () =>
          import('./common-setup/common-setup.module').then((m) => m.CommonSetupModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-security',
        loadChildren: () => import('./manage-security/manage-security.module').then(m => m.ManageSecurityModule),
        canActivate: [AuthGuard]
      },
      {
        path:'customer-ou',
        loadChildren: () =>          
          import('./customer-ou/customer-ou.module').then((m) => m.CustomerOuModule),
        canActivate: [AuthGuard]
      },
      {
        path:'charges',
        loadChildren: () =>          
          import('./commission-charges/commission-charges.module').then((m) => m.CommissionChargesModule),
        canActivate: [AuthGuard]
      },
      {
        path:'tax-setup',
        loadChildren: () =>          
          import('./tax-setup/tax-setup.module').then((m) => m.TaxSetupModule),
        canActivate: [AuthGuard]
      },
      {
        path:'transaction',
        loadChildren: () =>          
          import('./transaction/transaction.module').then((m) => m.TransactionModule),
        canActivate: [AuthGuard]
      },
      {
        path:'complaint',
        loadChildren: () =>          
          import('./complaint/complaint.module').then((m) => m.ComplaintModule),
        canActivate: [AuthGuard]
      },
      {
        path:'api-hub',
        loadChildren: () =>          
          import('./define-api-hub/define-api-hub.module').then((m) => m.DefineApiHubModule),
        canActivate: [AuthGuard]
      },
      {
        path:'report',
        loadChildren: () =>          
          import('./report/report.module').then((m) => m.ReportModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
